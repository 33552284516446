<template>
  <MainLayout back-to="/" :title="$t('help.title')" has-small-content :short-title="$t('help.title')">
    <template v-slot:content>
      <div class="box has-margin-top-medium has-text-centered">
        <p class="is-size-2 has-text-weight-bold has-margin-bottom">
          {{ $t('help.intro.contactUs') }}
        </p>
        <div class="columns is-mobile has-text-small">
          <div class="column" @click="$router.push('/support')">
            <p class="has-margin">
              <faicon class="has-text-primary" size="3x" icon="concierge-bell"></faicon>
            </p>
            <p>
              {{ $t('help.intro.support') }}
            </p>
          </div>
          <div class="column">
            <p class="has-margin">
              <faicon size="3x" icon="envelope"></faicon>
            </p>
            <p>{{ $t('help.intro.email') }} <a href="mailto:sgrh@uc.pt">sgrh@uc.pt</a>.</p>
          </div>
          <div class="column">
            <p class="has-margin">
              <faicon size="3x" icon="phone"></faicon>
            </p>
            <p>{{ $t('help.intro.phone') }} 239 242720/31.</p>
          </div>
        </div>
      </div>
      <div class="content has-margin-top-large">
        <h1>{{ $t('help.faqs.title') }}</h1>
        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.howToApply') }}?</h3>
        <div v-html="$t('help.faqs.howToApplyReply')"></div>

        <b-message class="has-margin-top has-margin-bottom" size="is-small">
          <p v-html="$t('help.faqs.howToApplyMessage')"></p>
        </b-message>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.inPerson') }}?</h3>
        <div v-html="$t('help.faqs.inPersonReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.requirement') }}?</h3>
        <div v-html="$t('help.faqs.requirementReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.mandatory') }}?</h3>
        <div v-html="$t('help.faqs.mandatoryReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.additionalInfo') }}?</h3>
        <div v-html="$t('help.faqs.additionalInfoReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.phdInternational') }}?</h3>
        <div v-html="$t('help.faqs.phdInternationalReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.fileTypes') }}?</h3>
        <div v-html="$t('help.faqs.fileTypesReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.relevantJobs') }}?</h3>
        <div v-html="$t('help.faqs.relevantJobsReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.uploadFolders') }}?</h3>
        <div v-html="$t('help.faqs.uploadFoldersReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.cvClassified') }}?</h3>
        <div v-html="$t('help.faqs.cvClassifiedReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.saveForLater') }}?</h3>
        <div v-html="$t('help.faqs.saveForLaterReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.reopenApplication') }}?</h3>
        <div v-html="$t('help.faqs.reopenApplicationReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.editApplication') }}?</h3>
        <div v-html="$t('help.faqs.editApplicationReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.afterSubmission') }}?</h3>
        <div v-html="$t('help.faqs.afterSubmissionReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.submissionProof') }}?</h3>
        <div v-html="$t('help.faqs.submissionProofReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.proofAbsent') }}?</h3>
        <div v-html="$t('help.faqs.proofAbsentReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.cannotSubmit') }}</h3>
        <div v-html="$t('help.faqs.cannotSubmitReply')"></div>

        <h3><faicon icon="question-circle" /> {{ $t('help.faqs.dl84_2019') }}?</h3>
        <div v-html="$t('help.faqs.dl84_2019Reply')"></div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    MainLayout,
  },
  data() {
    return {}
  },
}
</script>
